@charset "UTF-8";
/* stylelint-disable unit-whitelist, declaration-no-important, no-duplicate-selectors, selector-max-id, selector-class-pattern, selector-max-specificity */
/* stylelint-disable declaration-colon-newline-after */
/**
* @license
* MyFonts Webfont Build ID 3585883, 2018-05-23T06:14:54-0400
*
* The fonts listed in this notice are subject to the End User License
* Agreement(s) entered into by the website owner. All other parties are
* explicitly restricted from using the Licensed Webfonts(s).
*
* You may obtain a valid license at the URLs below.
*
* Webfont: Axiforma-Bold by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/bold/
*
* Webfont: Axiforma-BoldItalic by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/bold-italic/
*
* Webfont: Axiforma-Book by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/book/
*
* Webfont: Axiforma-Black by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/black/
*
* Webfont: Axiforma-BlackItalic by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/black-italic/
*
* Webfont: Axiforma-BookItalic by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/book-italic/
*
* Webfont: Axiforma-Italic by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/italic/
*
* Webfont: Axiforma-Heavy by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/heavy/
*
* Webfont: Axiforma-ExtraBold by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/extra-bold/
*
* Webfont: Axiforma-HeavyItalic by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/heavy-italic/
*
* Webfont: Axiforma-ExtraBoldItalic by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/extra-bold-italic/
*
* Webfont: Axiforma-Light by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/light/
*
* Webfont: Axiforma-LightItalic by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/light-italic/
*
* Webfont: Axiforma-Medium by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/medium/
*
* Webfont: Axiforma-MediumItalic by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/medium-italic/
*
* Webfont: Axiforma-Regular by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/regular/
*
* Webfont: Axiforma-SemiBold by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/semi-bold/
*
* Webfont: Axiforma-SemiBoldItalic by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/semi-bold-italic/
*
* Webfont: Axiforma-Thin by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/thin/
*
* Webfont: Axiforma-ThinItalic by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/thin-italic/
*
*
* License: https://www.myfonts.com/viewlicense?type=web&buildid=3585883
* Licensed pageviews: 100,000
* Webfonts copyright: Copyright &#x00A9; 2017 by Kastelov. All rights reserved.
*
* © 2018 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/36C607");
@font-face {
  font-family: "Axiforma";
  font-weight: 200;
  font-style: normal;
  src: url("../fonts/axiforma/36C607_B_0.eot");
  src: url("../fonts/axiforma/36C607_B_0.eot?#iefix") format("embedded-opentype"), url("../fonts/axiforma/36C607_B_0.woff2") format("woff2"), url("../fonts/axiforma/36C607_B_0.woff") format("woff"), url("../fonts/axiforma/36C607_B_0.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Axiforma";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/axiforma/36C607_2_0.eot");
  src: url("../fonts/axiforma/36C607_2_0.eot?#iefix") format("embedded-opentype"), url("../fonts/axiforma/36C607_2_0.woff2") format("woff2"), url("../fonts/axiforma/36C607_2_0.woff") format("woff"), url("../fonts/axiforma/36C607_2_0.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Axiforma";
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/axiforma/36C607_5_0.eot");
  src: url("../fonts/axiforma/36C607_5_0.eot?#iefix") format("embedded-opentype"), url("../fonts/axiforma/36C607_5_0.woff2") format("woff2"), url("../fonts/axiforma/36C607_5_0.woff") format("woff"), url("../fonts/axiforma/36C607_5_0.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Axiforma";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/axiforma/36C607_D_0.eot");
  src: url("../fonts/axiforma/36C607_D_0.eot?#iefix") format("embedded-opentype"), url("../fonts/axiforma/36C607_D_0.woff2") format("woff2"), url("../fonts/axiforma/36C607_D_0.woff") format("woff"), url("../fonts/axiforma/36C607_D_0.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Axiforma";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/axiforma/36C607_0_0.eot");
  src: url("../fonts/axiforma/36C607_0_0.eot?#iefix") format("embedded-opentype"), url("../fonts/axiforma/36C607_0_0.woff2") format("woff2"), url("../fonts/axiforma/36C607_0_0.woff") format("woff"), url("../fonts/axiforma/36C607_0_0.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Axiforma";
  font-weight: 700;
  font-style: italic;
  src: url("../fonts/axiforma/36C607_1_0.eot");
  src: url("../fonts/axiforma/36C607_1_0.eot?#iefix") format("embedded-opentype"), url("../fonts/axiforma/36C607_1_0.woff2") format("woff2"), url("../fonts/axiforma/36C607_1_0.woff") format("woff"), url("../fonts/axiforma/36C607_1_0.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Axiforma";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/axiforma/36C607_3_0.eot");
  src: url("../fonts/axiforma/36C607_3_0.eot?#iefix") format("embedded-opentype"), url("../fonts/axiforma/36C607_3_0.woff2") format("woff2"), url("../fonts/axiforma/36C607_3_0.woff") format("woff"), url("../fonts/axiforma/36C607_3_0.ttf") format("truetype");
  font-display: swap;
}
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-BlackItalic
    font-weight: 900;
    font-style: italic;
    src: url('../fonts/axiforma/36C607_4_0.eot');
    src: url('../fonts/axiforma/36C607_4_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_4_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_4_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_4_0.ttf') format('truetype');
} */
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-Italic
    font-weight: 400;
    font-style: italic;
    src: url('../fonts/axiforma/36C607_6_0.eot');
    src: url('../fonts/axiforma/36C607_6_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_6_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_6_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_6_0.ttf') format('truetype');
} */
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-Heavy
    src: url('../fonts/axiforma/36C607_7_0.eot');
    src: url('../fonts/axiforma/36C607_7_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_7_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_7_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_7_0.ttf') format('truetype');
} */
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-ExtraBold
    font-weight: 800;
    font-style: normal;
    src: url('../fonts/axiforma/36C607_8_0.eot');
    src: url('../fonts/axiforma/36C607_8_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_8_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_8_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_8_0.ttf') format('truetype');
} */
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-HeavyItalic
    src: url('../fonts/axiforma/36C607_9_0.eot');
    src: url('../fonts/axiforma/36C607_9_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_9_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_9_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_9_0.ttf') format('truetype');
} */
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-ExtraBoldItalic
    font-weight: 800;
    font-style: italic;
    src: url('../fonts/axiforma/36C607_A_0.eot');
    src: url('../fonts/axiforma/36C607_A_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_A_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_A_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_A_0.ttf') format('truetype');
} */
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-LightItalic
    font-weight: 200;
    font-style: italic;
    src: url('../fonts/axiforma/36C607_C_0.eot');
    src: url('../fonts/axiforma/36C607_C_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_C_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_C_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_C_0.ttf') format('truetype');
} */
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-MediumItalic
    font-weight: 500;
    font-style: italic;
    src: url('../fonts/axiforma/36C607_E_0.eot');
    src: url('../fonts/axiforma/36C607_E_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_E_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_E_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_E_0.ttf') format('truetype');
} */
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-Regular
    font-weight: 400;
    font-style: normal;
    src: url('../fonts/axiforma/36C607_F_0.eot');
    src: url('../fonts/axiforma/36C607_F_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_F_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_F_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_F_0.ttf') format('truetype');
} */
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-SemiBold
    font-weight: 600;
    font-style: normal;
    src: url('../fonts/axiforma/36C607_10_0.eot');
    src: url('../fonts/axiforma/36C607_10_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_10_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_10_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_10_0.ttf') format('truetype');
} */
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-SemiBoldItalic
    font-weight: 600;
    font-style: italic;
    src: url('../fonts/axiforma/36C607_11_0.eot');
    src: url('../fonts/axiforma/36C607_11_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_11_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_11_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_11_0.ttf') format('truetype');
} */
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-Thin
    font-weight: 100;
    font-style: normal;
    src: url('../fonts/axiforma/36C607_12_0.eot');
    src: url('../fonts/axiforma/36C607_12_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_12_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_12_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_12_0.ttf') format('truetype');
} */
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-ThinItalic
    font-weight: 100;
    font-style: italic;
    src: url('../fonts/axiforma/36C607_13_0.eot');
    src: url('../fonts/axiforma/36C607_13_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_13_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_13_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_13_0.ttf') format('truetype');
} */
/**
 * String replacement
 * @author Hugo Giraudel
 */
@page {
  margin: 2cm;
}
*,
*::before,
*::after {
  background: transparent !important;
  color: #000 !important;
}

body {
  position: relative;
  padding-bottom: 1.25rem;
}

.print-footer {
  display: block !important;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 0.75rem;
  text-align: center;
  color: #999 !important;
}

.print-footer__logo {
  width: 5.3125rem;
  margin-bottom: 0.3125rem;
}

p,
h2,
h3 {
  orphans: 3;
  widows: 3;
}

h2,
h3,
p {
  page-break-after: avoid;
}

img {
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}

.site-header,
.site-footer,
.nav,
.share,
#tilaus .section__heading,
#yhteystiedot,
#tarjouspyynto,
#toimitustapa,
#maksutapa,
.line-item__button,
.woocommerce-view-order .article__header,
.woocommerce-MyAccount-navigation,
.order-again,
.conversion-stripe,
.show-more-button,
.product__image-carousel-pagination,
.btn--buy,
.product__price-heading,
.product__add-to-cart,
.product-filters,
.product-search__footer,
.product-search__details,
.form-block,
.article ~ .section,
.cart-footer {
  display: none !important;
}

table,
th,
td {
  border: 1px solid #000;
}

.article {
  max-width: 15cm !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.swiper-wrapper {
  display: block !important;
}

.swiper-slide:nth-child(n+2) {
  display: none !important;
}

.swiper-slide {
  display: block !important;
  width: 100% !important;
  text-align: center;
}

.product__availability-container {
  margin-top: 0 !important;
}

.availability {
  padding-left: 0;
}

.product__price-container {
  text-align: left !important;
  margin-top: 1rem !important;
}

.price--big {
  display: flex !important;
}

.price-details--big {
  margin: 0 0 0 0.625rem;
}

.tax--big {
  padding: 0.5625rem 0 !important;
  line-height: normal !important;
}

.select::before, .select::after {
  content: none !important;
}
.select select {
  border: 0 !important;
}

.product__section {
  page-break-inside: avoid;
}

.product__section__text p:last-child {
  margin-bottom: 0 !important;
}

.product__variation {
  display: flex !important;
  overflow: hidden;
  margin-bottom: 0 !important;
}

.product__variation__label {
  float: left;
  margin-top: 0 !important;
}

.product__variation__field {
  width: 50% !important;
}
.product__variation__field select {
  padding-top: 0 !important;
}

.product-search__count {
  margin-top: 0 !important;
}

.product-search__results {
  margin-bottom: 1rem;
}

.product-list {
  display: flex;
  flex-flow: row wrap;
  max-width: none;
  border-top: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
}

.product-list__item {
  flex: 1 1 11.25rem;
  border-width: 0 1px 1px 0;
}

.product-teaser {
  min-width: 11.25rem !important;
}

.section--contacts:nth-child(n+2) {
  page-break-after: always;
  margin: 0 !important;
  padding: 0 !important;
  border: 0 !important;
}
.section--contacts .bp6-col-one-fifth,
.section--contacts .bp6-col-two-fifths {
  width: 33.33%;
}
.section--contacts .bp6-col-one-fifth:nth-child(3n+1),
.section--contacts .bp6-col-two-fifths:nth-child(3n+1) {
  clear: left;
}
.section--contacts:first-child .bp3-col-one-third {
  width: 33.33%;
}
.section--contacts:first-child .bp3-col-one-third .section--contacts__heading {
  font-size: 1.125rem;
}

.hero--section__content {
  padding-bottom: 1.25rem;
}

.hero--section__image-container {
  padding-bottom: 1.25rem;
}

.article__image-gallery .bp2-col-one-half {
  width: 50%;
}
.article__image-gallery .bp2-col-one-third {
  width: 33.33%;
}

.checkout__sidebar,
.checkout-summary {
  position: static !important;
}

.checkout-summary {
  padding: 0 0 1.875rem;
}

#tilaus {
  margin-bottom: 1rem;
}