$encoded-chars: (
    ('<', '%3C'),
    ('>', '%3E'),
    ('"', '\''),
    ('#', '%23'),
    ('&', '%26')
);

@function svg-encode($svg) {
    @each $char, $encoded in $encoded-chars {
        $svg: str-replace($svg, $char, $encoded);
    }

    @return 'data:image/svg+xml,' + $svg;
}

@mixin svg-icon-base() {
    display: inline-block;
    background: no-repeat center center / contain;
}

@mixin svg-icon-base-absolute() {
    position: absolute;
    background: no-repeat center center / contain;
}

@mixin svg-icon-base-pseudo() {
    content: '';
    display: inline-block;
    background: no-repeat center center / contain;
}

@mixin svg-icon-base-pseudo-absolute() {
    content: '';
    position: absolute;
    background: no-repeat center center / contain;
}

@mixin svg-icon($svg, $color: false) {
    $svg: str-replace($svg, 'stroke-width', 'vector-effect="non-scaling-stroke" stroke-width');

    @if ($color) {
        $svg: str-replace($svg, '#000', $color);
    }

    background-image: url('#{svg-encode("#{$svg}")}');
}

/**
 * String replacement
 * @author Hugo Giraudel
 */

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}
